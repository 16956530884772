<template>
    <div class="content">
        <Project 
            v-for="project in projects" 
            :key="project.id"
            :title="project.title"
            :phases="project.phases"
        />
    </div>
</template>

<script>
import Project from '@/components/Project'

export default {
    name:'Content',
    props: {
        projects: Array
    },
    components: {
        Project,
    }
}
</script>

<style scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

        width: 100vw;
    max-width: 100vw;
    min-width: 100vw;

        height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
    min-height: calc(100vh - 320px);
}
</style>