<template>
    <div class="footer">
        {{ info }}
    </div>
</template>

<script>
export default {
    name:'Footer',
    components: {

    }, 
    props: {
        info: String,
    }
}
</script>

<style scoped>
.footer, h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

        width: 100vw;
    max-width: 100vw;
    min-width: 100vw;

        height: 50px;
    max-height: 50px;
    min-height: 50px;

    position: fixed;
    bottom: 0;

    background-color: var(--main-title-background);
    color: var(--main-title-font);
    font-size: 0.9em;

    border-top: var(--footer-border-size) solid var(--footer-border);
}
</style>