<template>
    <div class="element">
        <Icon :classlist="icon" :path="path" />
        <Label 
            :title="title" 
            :description="description" 
            :path="path"
        />
    </div>
</template>

<script>
import Icon from '@/components/Icon'
import Label from '@/components/Label'

export default {
    name: 'Element',
    components: {
        Icon, 
        Label
    },
    props: {
        icon: String,
        path: String,
        title: String,
        description: String
    }
}
</script>

<style scoped>
.element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5px;
}
</style>