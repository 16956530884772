<template>
    <div class="project-header">
        <header>
            <div>
                <strong>
                    {{ title }}
                </strong>
            </div>
        </header>
    </div>
</template>

<script>
export default {
    name:'ProjectHeader',
    props: {
        title: String
    }
}
</script>

<style scoped>
.project-header header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

        width: 100vw;
    max-width: 512px;
    min-width: 320px;

        height: 50px;
    max-height: 50px;
    min-height: 50px;

    text-align: center;
}
.project-header header div {
    border-bottom:1px solid var(--main-font-color);
}
</style>