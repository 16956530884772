<template>
    <div class="icon">
        <a :href="path">
            <i :class="classlist" aria-hidden="true"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'icon',
    props: {
        classlist: String,
        path: String
    }
}
</script>

<style scoped>
.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

        width: 50px;
    max-width: 50px;
    min-width: 50px;

        height: 50px;
    max-height: 50px;
    min-height: 50px;
}
.icon a {
    text-decoration: underline;
}
.blank {
    color: var(--main-title-font);
}
</style>