<template>
	<div class="container">
		<Header :title="title" />
		<Page :projects="projects" />
		<Footer :info="info" />
	</div>
</template>

<script>
import Header from '@/components/Header'
import Page from '@/components/Page'
import Footer from '@/components/Footer'

export default {
	name: 'App',
	components: {
		Header,
		Page,
		Footer
	},
	data() {
		return {
			title: "Agenda des Guinzes",
			info: "Copyright © 2013-2021 - Nicolas Jacquemin",
			projects: [
				{
					id: "853bf9c3-8c69-458f-8c4d-8e3f17f1bd7f",
					title: "Agenda des Guinzes",
					phases: [
						{
							id: "973c4081-2af6-4df1-9f2c-687b95520a99",
							icon: "fa fa-beer",
							path: "https://agenda.guinzes.be",
							title: "Production",
							text: "L'ADG utilisé par tous."
						},
						{
							id: "263330cb-23e5-4720-b632-c7a4aed8bacd",
							icon: "fa fa-space-shuttle",
							path: "https://preprod.guinzes.be",
							title: "Pré-Production",
							text: "Vous êtes ici."
						},
						{
							id: "a78b4727-5baf-4bd1-9c37-406a14d7eaa9",
							icon: "fa fa-flask",
							path: "https://dev.guinzes.be",
							title: "Développement",
							text: "Madlabs.tech"
						}
					]
				}
			]
		}
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Open+Sans:wght@300&display=swap');

:root {
    /* main */
    --main-background:#ecf0f1;
    --main-font-color:#0a0a0a;
    --main-title-background:#c0392b;
	--main-title-font: #ecf0f1;

	--header-border-size: 0px;
	--header-border: #f39c12;

	--footer-border-size: 0px;
	--footer-border: #f39c12;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	font-family: 'Open Sans Condensed', sans-serif;
	color: var(--main-font-color);

	background-color: var(--main-background);
}
body, #app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

        width: 100vw;
    max-width: 100vw;
    min-width: 100vw;

        height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

        width: 100vw;
    max-width: 100vw;
    min-width: 100vw;

        height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
}
</style>
