<template>
    <article class="project">
        <ProjectHeader :title="title" />
        <main>
            <ProjectList :phases="phases" />
        </main>
    </article>
</template>

<script>
import ProjectHeader from '@/components/ProjectHeader'
import ProjectList from '@/components/List'

export default {
    name:'Project',
    components: {
        ProjectHeader,
        ProjectList
    },
    props: {
        title: String,
        phases: Array
    }
}
</script>

<style scoped>
.project {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

        width: 100vw;
    max-width: 512px;
    min-width: 320px;

        height: calc(100vh - 350px);
    max-height: calc(100vh - 350px);
    min-height: calc(100vh - 350px);
}
</style>