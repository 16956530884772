<template>
    <div class="header">
        <h3>{{ title }}</h3>
    </div>
</template>

<script>
export default {
    name:'Header',
    components: {

    }, 
    props: {
        title: {
            type: String,
            default: 'Guinzes.be'
        }
    }
}
</script>

<style scoped>
.header, h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

        width: 100vw;
    max-width: 100vw;
    min-width: 100vw;

        height: 50px;
    max-height: 50px;
    min-height: 50px;

    background-color: var(--main-title-background);
    color: var(--main-title-font);

    border-bottom: var(--header-border-size) solid var(--header-border);
}
</style>