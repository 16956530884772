<template>
    <div class="label">
        <strong>
            <a :href="path">
                {{ title }}
                <i class="fa fa-link" aria-hidden="true"></i>
            </a>
        </strong>
        <p>{{ description }}</p>
    </div>
</template>

<script>
export default {
    name: 'Label',
    props: {
        title: String,
        description: String,
        path: String
    }
}
</script>

<style scoped>
.label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

        width: calc(100vw - 50px);
    max-width: calc(512px - 50px);
    min-width: calc(320px - 50px);

    padding: 0 0 0 3px;

    border-left: 1px solid var(--main-font-color);
}
.label a {
    text-decoration: none;
}
.label p {
    font-size: 0.85em;
}
</style>