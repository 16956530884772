<template>
    <div class="page">
        <Title />
        <Intro />
        <Content :projects="projects" />
    </div>
</template>

<script>
import Title from '@/components/Title'
import Intro from '@/components/Intro'
import Content from '@/components/Content'

export default {
    name:'Page',
    components: {
        Title,
        Intro,
        Content,
    },
    props: {
        projects: Array
    }
}
</script>

<style scoped>
.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

        width: 100vw;
    max-width: 100vw;
    min-width: 100vw;

        height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);

    padding-bottom: 500px;
}

</style>