<template>
    <div class="title">
        <h3>Bienvenue !</h3>
    </div>
</template>

<script>
export default {
    name:'Title'
}
</script>

<style scoped>
.title {
    padding: 15px 5px;
}
</style>