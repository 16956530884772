<template>
    <div class="list">
        <Element 
            v-for="phase in phases" 
            :key="phase.id"
            :icon="phase.icon"
            :path="phase.path"
            :title="phase.title"
            :description="phase.text"
        />
    </div>    
</template>

<script>
import Element from '@/components/Element'

export default {
    name: 'List',
    props: {
        phases: Array
    },
    components: {
        Element
    }
}
</script>

<style scoped>
.list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

        width: 100vw;
    max-width: 512px;
    min-width: 320px;
}
</style>